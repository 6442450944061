import React, { useState } from "react";
import { X, ChevronLeft, ChevronRight, Play } from "lucide-react"; // Added Play icon from lucide-react

//Images
import pune1 from "../Imeges/puen.jpg";
import pune2 from "../Imeges/pune.jpg";
import pune3 from "../Imeges/puned.jpg";
import pune4 from "../Imeges/punek.jpg";
import pune5 from "../Imeges/punekad.jpg";
import pune6 from "../Imeges/punekaes.jpg";
import pune7 from "../Imeges/punekar.jpg";
import pune8 from "../Imeges/punekar1.jpg";
import pune9 from "../Imeges/punekar12.jpg";
import pune10 from "../Imeges/punekar121.jpg";
import pune11 from "../Imeges/punekara.jpg";

import pune13 from "../Imeges/punekars.jpg";
import pune14 from "../Imeges/punekd.jpg";
import pune15 from "../Imeges/puneks.jpg";
import pune16 from "../Imeges/punes.jpg";
import pune17 from "../Imeges/pusnkjs.jpg";
import pune18 from "../Imeges/old1.jpeg";
import pune20 from "../Imeges/old2.jpeg";
import pune21 from "../Imeges/old3.jpeg";
import pune22 from "../Imeges/old4.jpeg";
import pune23 from "../Imeges/old5.jpeg";
import pune24 from "../Imeges/old6.jpeg";
import pune25 from "../Imeges/old7.jpeg";
import pune26 from "../Imeges/old8.jpeg";
import pune27 from "../Imeges/old9.jpeg";
import pune28 from "../Imeges/old10.jpeg";
import pune29 from "../Imeges/old11.jpeg";
import pune31 from "../Imeges/old12.jpeg";
import pune32 from "../Imeges/old13.jpeg";
import pune33 from "../Imeges/old14.jpeg";
import pune34 from "../Imeges/old15.jpeg";

import a1 from '../Imeges/oct/1.jpeg';
import a2 from '../Imeges/oct/2.jpeg';
import a3 from '../Imeges/oct/3.jpeg';
import a4 from '../Imeges/oct/4.jpeg';
import a5 from '../Imeges/oct/5.jpeg';
import a6 from '../Imeges/oct/6.jpeg';
import a7 from '../Imeges/oct/7.jpeg';
import a8 from '../Imeges/oct/8.jpeg';
import a9 from '../Imeges/oct/9.jpeg';
import a10 from '../Imeges/oct/10.jpeg';


//videos
import video1 from "../Imeges/pune.mp4";
import video2 from "../Imeges/punejs.mp4";
import video3 from "../Imeges/punekar.mp4";
import video4 from "../Imeges/puneks-ss.mp4";
import video5 from "../Imeges/puneks.mp4";
import video6 from "../Imeges/puneksc.mp4";
import video7 from "../Imeges/puneksd.mp4";
import video8 from "../Imeges/oldv1.mp4";
import video9 from "../Imeges/oldv2.mp4";
import video10 from "../Imeges/oldv3.mp4";
import video11 from "../Imeges/oldv4.mp4";
import video12 from "../Imeges/oldv5.mp4";
import video13 from "../Imeges/oldv6.mp4";
import video14 from "../Imeges/oldv7.mp4";
import video15 from "../Imeges/oldv8.mp4";
import video16 from "../Imeges/oldv9.mp4";
import WhatsApp from "../WhatsApp";

import v1 from '../Imeges/oct/v1.mp4'
import v2 from '../Imeges/oct/v2.mp4'
import v3 from '../Imeges/oct/v3.mp4'
import v4 from '../Imeges/oct/v4.mp4'

const galleryItems = [
  { id: 1, type: "image", src: pune1, alt: "Pune 1" },
  // { id: 2, type: "image", src: pune2, alt: "Pune 2" },
  // { id: 3, type: "image", src: pune3, alt: "Pune 3" },
  // { id: 4, type: "image", src: pune4, alt: "Pune 4" },
  // { id: 5, type: "image", src: pune5, alt: "Pune 5" },
  // { id: 6, type: "image", src: pune6, alt: "Pune 6" },
  // { id: 7, type: "image", src: pune7, alt: "Pune 7" },
  // { id: 8, type: "image", src: pune8, alt: "Pune 8" },
  // { id: 9, type: "image", src: pune9, alt: "Pune 9" },
  // { id: 10, type: "image", src: pune10, alt: "Pune 10" },
  { id: 11, type: "image", src: pune11, alt: "Pune 11" },
  // { id: 13, type: "image", src: pune13, alt: "Pune 13" },
  { id: 14, type: "image", src: pune14, alt: "Pune 14" },
  { id: 15, type: "image", src: pune15, alt: "Pune 15" },
  { id: 16, type: "image", src: pune16, alt: "Pune 16" },
  { id: 17, type: "image", src: pune17, alt: "Pune 17" },
  { id: 18, type: "image", src: pune18, alt: "Pune 18" },
  { id: 19, type: "image", src: pune18, alt: "Pune 18" },
  { id: 21, type: "image", src: pune20, alt: "Pune 20" },
  { id: 22, type: "image", src: pune21, alt: "Pune 21" },
  { id: 23, type: "image", src: pune22, alt: "Pune 22" },
  { id: 24, type: "image", src: pune23, alt: "Pune 23" },
  { id: 25, type: "image", src: pune24, alt: "Pune 24" },
  { id: 26, type: "image", src: pune25, alt: "Pune 25" },
  { id: 27, type: "image", src: pune26, alt: "Pune 26" },
  { id: 28, type: "image", src: pune27, alt: "Pune 28" },
  { id: 29, type: "image", src: pune28, alt: "Pune 29" },
  { id: 30, type: "image", src: pune29, alt: "Pune 30" },
  { id: 31, type: "image", src: pune31, alt: "Pune 30" },
  { id: 32, type: "image", src: pune32, alt: "Pune 30" },
  { id: 33, type: "image", src: pune33, alt: "Pune 30" },
  { id: 34, type: "image", src: pune34, alt: "Pune 30" },

  { id: 35, type: "image", src: a1, alt: "Pune 23" },
  { id: 35, type: "image", src: a2, alt: "Pune 24" },
  { id: 36, type: "image", src: a3, alt: "Pune 25" },
  { id: 37, type: "image", src: a4, alt: "Pune 26" },
  { id: 38, type: "image", src: a5, alt: "Pune 28" },
  { id: 39, type: "image", src: a6, alt: "Pune 29" },
  { id: 40, type: "image", src: a7, alt: "Pune 30" },
  { id: 41, type: "image", src: a8, alt: "Pune 30" },
  { id: 42, type: "image", src: a9, alt: "Pune 30" },
  { id: 43, type: "image", src: a10, alt: "Pune 30" },

  //Videos
  { id: 18, type: "video", src: video1, alt: "Pune Video 1" },
  { id: 19, type: "video", src: video2, alt: "Pune Video 2" },
  { id: 20, type: "video", src: video3, alt: "Pune Video 3" },
  { id: 21, type: "video", src: video4, alt: "Pune Video 4" },
  { id: 22, type: "video", src: video5, alt: "Pune Video 5" },
  { id: 23, type: "video", src: video6, alt: "Pune Video 6" },
  { id: 24, type: "video", src: video7, alt: "Pune Video 7" },
  { id: 25, type: "video", src: video8, alt: "Pune Video 8" },
  { id: 26, type: "video", src: video9, alt: "Pune Video 9" },
  { id: 27, type: "video", src: video10, alt: "Pune Video 10" },
  { id: 28, type: "video", src: video11, alt: "Pune Video 11" },
  { id: 29, type: "video", src: video12, alt: "Pune Video 12" },
  { id: 30, type: "video", src: video13, alt: "Pune Video 13" },
  { id: 31, type: "video", src: video14, alt: "Pune Video 14" },
  { id: 32, type: "video", src: video15, alt: "Pune Video 15" },
  { id: 33, type: "video", src: video16, alt: "Pune Video 16" },
  { id: 34, type: "video", src: v1, alt: "Pune Video 13" },
  { id: 35, type: "video", src: v2, alt: "Pune Video 14" },
  { id: 36, type: "video", src: v3, alt: "Pune Video 15" },
  { id: 37, type: "video", src: v4, alt: "Pune Video 16" },
];

const GalleryPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
  };

  const closeModal = (e) => {
    if (e.target.classList.contains("modal-background")) {
      setSelectedItem(null);
    }
  };

  const navigateItem = (direction) => {
    const currentIndex = galleryItems.findIndex(
      (item) => item.id === selectedItem.id
    );
    const newIndex =
      (currentIndex + direction + galleryItems.length) % galleryItems.length;
    setSelectedItem(galleryItems[newIndex]);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-extrabold mb-8 text-center">
        Punekar Gallery
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {galleryItems.map((item) => (
          <div
            key={item.id}
            className="relative cursor-pointer group"
            onClick={() => openModal(item)}
          >
            {item.type === "image" ? (
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
              />
            ) : (
              <div className="relative">
                <video
                  src={item.src}
                  className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                  muted
                  loop
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <Play size={48} className="text-white opacity-80" />{" "}
                  {/* Play icon on top of video */}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {selectedItem && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 modal-background transition-opacity duration-300"
          onClick={closeModal}
        >
          <div className="relative max-w-4xl w-full p-4 transition-transform duration-300 transform scale-100">
            <button
              onClick={() => setSelectedItem(null)}
              className="absolute top-4 right-4 text-white"
              aria-label="Close Modal"
            >
              <X size={36} />
            </button>
            <div className="relative">
              {selectedItem.type === "image" ? (
                <img
                  src={selectedItem.src}
                  alt={selectedItem.alt}
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              ) : (
                <video
                  src={selectedItem.src}
                  controls
                  autoPlay
                  loop
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              )}
              <button
                onClick={() => navigateItem(-1)}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
                aria-label="Previous Item"
              >
                <ChevronLeft size={36} />
              </button>
              <button
                onClick={() => navigateItem(1)}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all"
                aria-label="Next Item"
              >
                <ChevronRight size={36} />
              </button>
            </div>
          </div>
        </div>
      )}
      <WhatsApp />
    </div>
  );
};

export default GalleryPage;
